import GLightbox from 'glightbox';

"use strict";

const lightbox = {
    multiInstances: [],
    singleInstance: null,
    moreText: 'Ler Mais',
    previousUrl: null,
    elements: {
        single: '.js-lightbox',
        multi: '.js-lightbox-multi',
        onload: '.js-lightbox-onload',
    },
    svg: {
        close: '<svg viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M19.5912 18.4777L2.68537 2L2 2.62348L18.2662 18.4777L2 34.332L2.68537 35L19.5912 18.4777Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M18.4088 18.4777L35.3146 2L36 2.62348L19.7338 18.4777L36 34.332L35.3146 35L18.4088 18.4777Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M19.0014 19.0556L2.68681 35.0001L2.00143 34.3321L18.2676 18.4778L2.00143 2.62357L2.68681 2.00009L19.0014 17.9016L35.3161 2.00009L36.0014 2.62357L19.7352 18.4778L36.0014 34.3321L35.3161 35.0001L19.0014 19.0556ZM19.0014 20.4539L35.3151 36.3974L37.4341 34.3321L21.1679 18.4778L37.4599 2.59846L35.2924 0.626709L19.0014 16.5051L2.71045 0.62671L0.542969 2.59846L16.8349 18.4778L0.56873 34.3321L2.68775 36.3974L19.0014 20.4539Z" fill="white"/></svg>',
        prev: '<svg viewBox="0 0 42 75" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.99935 37.7636L38.3693 2.00011L39.8438 3.35332L4.84996 37.7636L39.8438 72.1739L38.3693 73.6238L1.99935 37.7636Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M0.722656 37.7627L38.3678 74.8802L41.12 72.1739L6.12624 37.7636L41.1428 3.33093L38.3474 0.765381L0.722656 37.7627ZM4.84996 37.7636L39.8438 72.1739L38.3693 73.6238L1.99935 37.7636L38.3693 2.00011L39.8438 3.35332L4.84996 37.7636Z" fill="white"/></svg>',
        next: '<svg viewBox="0 0 41 75" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M39.3678 37.7637L2.99789 2.00021L1.52344 3.35343L36.5172 37.7637L1.52344 72.174L2.99789 73.6239L39.3678 37.7637Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M40.6428 37.7627L2.99765 74.8802L0.245421 72.1739L35.2392 37.7636L0.222656 3.33093L3.01806 0.765381L40.6428 37.7627ZM36.5161 37.7635L1.52234 72.1739L2.9968 73.6238L39.3667 37.7636L2.9968 2.0001L1.52234 3.35332L36.5161 37.7635Z" fill="white"/></svg>',
    },

    init() {
        const self = this;

        if (document.querySelector(self.elements.single)) {
            self.single();
        }

        if (document.querySelector(self.elements.multi)) {
            self.multi();
        }

        /*
        if (document.querySelector(self.elements.onload)) {
            document.querySelectorAll(self.elements.onload).forEach(function(el, i) {
                self.currentI = i;
                // el.click();
                console.log('lightbox init');
            });
        }
        */
    },

    checkOpen() {
        const self = this;
        if (document.querySelector(self.elements.onload)) {
            document.querySelectorAll(self.elements.onload).forEach(function(el, i) {
                self.currentI = i;
                el.click();
                console.log('lightbox init');
            });
        }
    },

    closeAllInstances() {
        const self = this;

        if (self.multiInstances.length) {
            self.multiInstances.forEach(function(instance) {
                instance.close();
            });
        }
    },

    reset() {
        const self = this;
        console.log('lightbox reset')
        if (self.singleInstance) {
            self.singleInstance.destroy();
            self.singleInstance = null;
        }

        document.querySelectorAll(self.elements.multi).forEach(function(el, i) {
            self.currentI = i;
            el.removeEventListener('click', self.multiClick, false);
        });

        if (document.querySelector(self.elements.single)) {
            self.single();
        }

        if (document.querySelector(self.elements.multi)) {
            self.multi();
        }
        
        /*
        if (document.querySelector(self.elements.onload)) {
            document.querySelectorAll(self.elements.onload).forEach(function(el, i) {
                self.currentI = i;
                el.click();
            });
        }
        */
    },

    single() {
        const self = this;

        self.singleInstance = GLightbox({
            selector: self.elements.single,
            touchNavigation: true,
            loop: false,
            autoplayVideos: true,
            videosWidth: '70vw',
            moreText: self.moreText,
            svg: {
                close: self.svg.close,
                prev: self.svg.prev,
                next: self.svg.next,
            },
        });
    },
    multi() {
        const self = this;

        document.querySelectorAll(self.elements.multi).forEach(function(el, i) {
            self.currentI = i;
            el.addEventListener('click', self.multiClick, false);
        });


    },
    multiClick(e) {
        const self = lightbox;
        const el = e.currentTarget;
        const i = self.currentI;

        e.preventDefault();

        const href = el.getAttribute('href');

        const xhr = new XMLHttpRequest();

        xhr.open('GET', href, true);

        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("Authorization", "Bearer your_access_token");


        xhr.send();

        xhr.onload = function() {
            // Handle successful response
            console.log(JSON.parse(xhr.responseText));
            const elements = JSON.parse(xhr.responseText);

            self.multiInstances[i] = GLightbox({
                touchNavigation: true,
                loop: false,
                autoplayVideos: true,
                videosWidth: '70vw',
                moreText: self.moreText,
                svg: {
                    close: self.svg.close,
                    prev: self.svg.prev,
                    next: self.svg.next,
                },
                elements: elements
            });
            
            self.multiInstances[i].open();

            // update history url
            const url = new URL(href);
            
            
            if (url.pathname !== window.location.pathname) {
                // console.log('update history url', url.pathname, window.location.pathname);
                window.history.pushState({}, '', `${url.pathname}`);
            } else {
                // console.log('do not update history, l' + url.pathname, window.location.pathname);
            }

            /*
            const currentUrl = document.querySelector('.js-current-url').textContent;

            self.multiInstances[i].on('close', () => {
                window.history.pushState({}, '', currentUrl);
            });
            */

        };
        
        xhr.onerror = function() {
        // Handle error
        };
    }
};


export default lightbox

