import jsToggle from './toggle.js';

"use strict";

const filters = {
    activeFilters: [],
    instance: null,
    elements: {
        root: '.js-filters-root',
        filter: '.js-filters',
        item: '.js-filters-item'
    },
    clickHandler: function (event) {
        // Your click event handler logic here

        const self = this;
        // Your click event handler logic here
        // Close parent menu
        jsToggle.closeAll();
        const $this = event.currentTarget,
            filter = $this.getAttribute('data-filter');

        const filterKey = filter.split('--')[0];
        const filterValue = filter.split('--')[1];

        if (!filter) return false;

        if (filterValue == '*' || self.isFilterActive(filter)) {
            self.removeFilter(filter, $this);
        } else {   
            self.pushFilters(filter, $this);
        }

        self.updateUrlParams();

    },
    init() {
        const self = this;
        self.instance = null;

        if (!document.querySelector(self.elements.root)) return false;

        self.click = self.click.bind(self); // Bind the click function to 'this'
        self.clickHandler = self.clickHandler.bind(self); // Bind the clickHandler function to 'this'
        self.click();
    },
    reset() {
        const self = this;
        document.querySelectorAll(self.elements.filter).forEach((item) => {
            item.removeEventListener('click', self.clickHandler); // Remove the click event listener
        });

        self.activeFilters = [];

        // Reset the activeFilters and other state as needed

        // Reinitialize the object/module
        self.init();
    },
    click() {
        const self = this;
        document.querySelectorAll(self.elements.filter).forEach((item) => {
            item.addEventListener("click", self.clickHandler);
        });
    },
    pushFilters(filter, $this) {
        const self = this;

        const filterKey = filter.split('--')[0];
        const filterValue = filter.split('--')[1];
        
        document.querySelectorAll(self.elements.filter).forEach((item) => {
            const thisFilter = item.getAttribute('data-filter');

            if (!thisFilter) return false;

            const thisFilterKey = thisFilter.split('--')[0];

            if (thisFilterKey != filterKey) return false;
            
            item.classList.remove('is-active');
        });

        document.querySelector('[data-filter="' + filter + '"]').classList.add('is-active');
        self.activeFilters[filterKey] = filterValue;

        const filters = self.prepareFilters();

        const $parentMenu = $this.closest('.js-toggle-target');

        if ($parentMenu ) {
            const slug = $parentMenu.getAttribute('data-toggle-slug');
            const $trigger = document.querySelector('.js-toggle-trigger[data-toggle-slug="' + slug + '"]');

            if (!filter.includes('*')) {
                if ($trigger) $trigger.classList.add('has-active');
            } else {
                if ($trigger) $trigger.classList.remove('has-active');
            }
        }
        
        document.querySelector(self.elements.root).classList.add('is-busy');

        setTimeout(() => {
            document.querySelectorAll(self.elements.item).forEach((item) => {
                item.classList.add('is-hidden');
                if (filters == '') {
                    item.classList.remove('is-hidden');
                } else if (item.matches(filters)) {
                    item.classList.remove('is-hidden');
                }
            });
        }, 250);

        setTimeout(() => {
            const activeItemsLength = document.querySelectorAll(self.elements.item + ':not(.is-hidden)').length ? true : false;
            document.querySelector(self.elements.root).classList.toggle('is-empty', !activeItemsLength);
        }, 350);

        setTimeout(() => {
            document.querySelector(self.elements.root).classList.remove('is-busy');
            console.log('self.activeFilters', self.activeFilters);
        }, 500);
    },

    removeFilter(filter, $this) {

        const self = this;

        const filterKey = filter.split('--')[0];

        document.querySelectorAll(self.elements.filter).forEach((item) => {
            const thisFilter = item.getAttribute('data-filter');

            if (!thisFilter) return false;

            const thisFilterKey = thisFilter.split('--')[0];

            if (thisFilterKey != filterKey) return false;

            item.classList.remove('is-active');

        });

        delete self.activeFilters[filterKey];

        const filters = self.prepareFilters();


        const $parentMenu = $this.closest('.js-toggle-target');

        if ($parentMenu ) {
            const slug = $parentMenu.getAttribute('data-toggle-slug');
            const $trigger = document.querySelector('.js-toggle-trigger[data-toggle-slug="' + slug + '"]');

            if ($trigger) $trigger.classList.remove('has-active');

            // find child * and add active class
            $parentMenu.querySelector('[data-filter*="--*"]').classList.add('is-active');
        }

        document.querySelector(self.elements.root).classList.add('is-busy');

        setTimeout(() => {
            document.querySelectorAll(self.elements.item).forEach((item) => {
                item.classList.add('is-hidden');
                if (filters == '') {
                    item.classList.remove('is-hidden');
                } else if (item.matches(filters)) {
                    item.classList.remove('is-hidden');
                }
            });
        }, 250);

        setTimeout(() => {
            const activeItemsLength = document.querySelectorAll(self.elements.item + ':not(.is-hidden)').length ? true : false;
            document.querySelector(self.elements.root).classList.toggle('is-empty', !activeItemsLength);
        }, 350);

        setTimeout(() => {
            document.querySelector(self.elements.root).classList.remove('is-busy');
        }, 500);
    },

    prepareFilters() {
        const self = this;

        let filters = '';

        for (const key in self.activeFilters) {
            if (self.activeFilters.hasOwnProperty(key)) {
                const value = self.activeFilters[key];
                filters += '.' + key + '--' + value;
            }
        }

        return filters;
    },

    isFilterActive(filter) {
        const self = this;
        const key = filter.split('--')[0];
        const value = filter.split('--')[1];

        // console.log(key, self.activeFilters[key], filter.split('--')[1]);
        if (self.activeFilters[key] && self.activeFilters[key] == value) {
            return true;
        }

        return false;
    },

    updateUrlParams() {
    }

};


export default filters

